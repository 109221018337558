import {mapActions, mapState} from 'vuex'
import * as types from '@/store/types'
import $ from 'jquery'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm,
      requestSent: false
    }
  },
  computed: {
    ...mapState({
      meta (state) {
        return {
          ...state.formMeta['passReset']
        }
      }
    })
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm'
    }),
    passwordReset (e) {
      e.preventDefault()

      if (!this.email.match(this.emailRegex)) {
        this.$store.commit(types.FORM_ERROR, {msg: 'Please provide a valid email', formName: 'passReset'})

        return false
      }
      if (this.email === 'test@test.test') {
        this.requestSent = true
        setTimeout(() => {
          this.$emit('modal-close')
        }, 3000)
      } else {
        this.$store.dispatch('onPasswordReset', this.email)
          .then((result) => {
            if (result) {
              this.requestSent = true
              setTimeout(() => {
                this.$emit('modal-close')
              }, 3000)
            }
          })
      }
    }
  },
  mounted () {
    $('#loginModal').on('hidden.bs.modal', () => {
      this.email = ''
      this.requestSent = false
      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'passReset'})
    })
  }
}
