module.exports = {
  prod: {
    host: 'prod-yii.omnismain.com',
    protocol: 'https',
    port: 443,
    index: 'ss_yii_1.5',
    type: 'items'
  },
  dev: {
    host: 'yii.omnismain.com',
    protocol: 'https',
    port: 443,
    index: 'ss_yii_1.6',
    type: 'items'
  }
}
