import {mapActions, mapState} from 'vuex'
import * as types from '@/store/types'
import $ from 'jquery'

export default {
  name: 'SignUp',
  data () {
    return {
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        subscribed: 0
      },
      emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm,
      showPassword: false
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      auth: (state) => state.authorization,
      user: (state) => {
        const {...user} = state.user
        return user
      },
      profile: (state) => {
        const {user: {profile: data = {}}} = state
        const {...profile} = data
        return profile
      },
      meta: (state) => {
        return {
          ...state.formMeta['modalRegister'],
          message: state.formMeta.message
        }
      }
    })
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm'
    }),
    checkFields () {
      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'modalRegister'})

      if (!this.newUser.email.match(this.emailRegex)) {
        this.$store.commit(types.FORM_ERROR_FIELD, {
          msg: 'Please provide a valid email',
          field: 'email',
          formName: 'modalRegister'
        })
      }
      if (!this.newUser.password) {
        this.$store.commit(types.FORM_ERROR_FIELD, {
          msg: 'Please enter a password',
          field: 'password',
          formName: 'modalRegister'
        })
      }
      if (!this.newUser.firstName || !this.newUser.lastName) {
        this.$store.commit(types.FORM_ERROR_FIELD, {
          msg: 'Please enter your name',
          field: 'name',
          formName: 'modalRegister'
        })
      }
      return (!!this.newUser.email.match(this.emailRegex) && !!this.newUser.password &&
        !!this.newUser.firstName && !!this.newUser.lastName)
    },
    submitRegister (e) {
      e.preventDefault()

      if (this.checkFields()) {
        const user = {
          user_number: this.user.user_number,
          email: this.newUser.email,
          password: this.newUser.password,
          profile: {
            profile_number: this.profile.profile_number,
            email: this.newUser.email,
            first_name: this.newUser.firstName,
            last_name: this.newUser.lastName,
            subscribed: +this.newUser.subscribed
          }
        }
        this.$store.dispatch('onModalRegister', {data: user})
          .catch(err => console.log('Register form error: ', err))
          .then(() => {
            if (this.auth === 'authorized') this.$emit('modal-close')
          })
      }
    }
  },
  mounted () {
    $('#loginModal').on('hidden.bs.modal', () => {
      this.newUser.firstName = ''
      this.newUser.lastName = ''
      this.newUser.email = ''
      this.newUser.password = ''
      this.newUser.subscribed = true

      this.showPassword = false

      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'modalRegister'})
    })
  }
}
