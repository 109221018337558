var path = require('path')

module.exports = {
  env: require('./andrey-home-prod.env'),
  port: 1111,
  apiHost: 'https://yii.local',
  // apiHost: 'https://prod-yii.omnismain.com',
  integrationHost: 'https://tt.selectspecs.com',
  cdnUrl: 'https://d9qzjwuieyamt.cloudfront.net',
  cdnUrlPrefix: '/public/de/',
  prefix: '/rest',
  buildPrefix: 'home-prod',
  integrationPrefix: '/api',
  index: path.resolve(__dirname, '../dist/index.html'),
  assetsRoot: path.resolve(__dirname, '../dist'),
  assetsSubDirectory: 'static',
  assetsPublicPath: '/',
  defLocale: 'en',
  subDomain: '',
  cssSourceMap: true,
  basket: require('./Components/basket').prod,
  cartSSv4: require('./Components/cartSSv4').prod,
  products: require('./Components/products').prod,
  currencies: require('./Components/currencies').prod,
  countries: require('./Components/countries').prod,
  checkout: require('./Components/checkout').prod,
  prescriptions: require('./Components/prescriptions').prod,
  wishlist: require('./Components/wishlist').prod,
  orders: require('./Components/orders').prod,
  profiles: require('./Components/profiles').prod,
  users: require('./Components/users').prod,
  menu: require('./Components/menu').prod,
  routes: require('./Components/routes').prod,
  elastic: {
    host: 'yii.omnismain.com',
    protocol: 'https',
    port: 443,
    index: 'ss_yii_1.1',
    type: 'items'
  },
  designers: require('./Components/designers').prod,
  reviews: require('./Components/reviews').prod,
  categories: require('./Components/categories').prod,
  gettexts: require('./Components/gettexts').prod,
  blocks: require('./Components/blocks').prod,
  // Gzip off by default as many popular static hosts such as
  // Surge or Netlify already gzip all static assets for you.
  // Before setting to `true`, make sure to:
  // npm install --save-dev compression-webpack-plugin
  productionGzip: true,
  productionGzipExtensions: ['js', 'css'],
  // Run the build command with an extra argument to
  // View the bundle analyzer report after build finishes:
  // `npm run build --report`
  // Set to `true` or `false` to always turn it on or off
  bundleAnalyzerReport: process.env.npm_config_report,

  // MUST BE DELETED FROM HERE!!!!!!!!!!!!!
  // MUST BE MOVED TO DATABASE!!!!!!!!!!!!!!
  vat: 1.2,
  cluster: {
    max_open_processes: 1,
    per_core: 1,
    limit: 1,
    compile: 'alpine-x64-10.13.0',
    step: 1,
    freeTimeout: 5, // minutes
    checkInterval: 1000,
    timeoutOnMaxWorkerReached: 300,
    memoryLimit: 256,
    maxPreFetchDepth: 10
  },
  ssv4: [
    // 'cart/index'
  ]
}
