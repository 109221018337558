import { render, staticRenderFns } from "@/components/templates/default/ProjectVersion/index.pug?vue&type=template&id=22d38d5f&scoped=true&lang=pug&"
import script from "./index.js?vue&type=script&lang=js&"
export * from "./index.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/ProjectVersion/index.scss?vue&type=style&index=0&id=22d38d5f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d38d5f",
  null
  
)

component.options.__file = "index.vue"
export default component.exports