module.exports = {
  prod: {
    getMenus: '/menu/main/get-menus-content',
    getMenuSSv4: '/fetch-menu/'
  },
  dev: {
    getMenus: '/menu/main/get-menus-content',
    getMenuSSv4: '/fetch-menu/'
  }
}
