import SignIn from '@/components/scripts/LoginModal/SignIn/index.vue'
import SignUp from '@/components/scripts/LoginModal/SignUp/index.vue'
import ForgotPassword from '@/components/scripts/LoginModal/ForgotPassword/index.vue'
import $ from 'jquery'

export default {
  name: 'LoginModal',
  components: {
    SignIn,
    SignUp,
    ForgotPassword
  },
  data () {
    return {
      title: 'Sign In',
      currentView: 'SignIn'
    }
  },
  methods: {
    switchView (event) {
      this.title = event.title
      this.currentView = event.view
    },
    modalClose (redirect) {
      $('#loginModal').modal('hide').on('hidden.bs.modal', () => {
        if (redirect) this.$router.push({name: 'AccountIndexPage'})
      })
    }
  },
  mounted () {
    $('#loginModal').on('hidden.bs.modal', () => {
      this.title = 'Sign In'
      this.currentView = 'SignIn'
    })
  }
}
