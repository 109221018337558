module.exports = {
  prod: {
    login: '/site/login',
    loginSsv4: '/login',
    logout: '/site/logout',
    get: '/users',
    resetPass: '/auth/restore_password/',
    checkToken: '/site/check-token'
  },
  dev: {
    login: '/site/login',
    logout: '/site/logout',
    loginSsv4: '/login',
    get: '/users',
    resetPass: '/auth/restore_password/',
    checkToken: '/site/check-token'
  }
}
