module.exports = {
  prod: {
    add: '/basket/add',
    itemDelete: '/basket/delete-item',
    clear: '/basket/clear',
    changeQuantity: '/basket/change-quantity',
    setCurrency: '/basket/set-currency',
    setCurrencyIntegration: '/checkout/checkout/set-currency',
    addToSave: '/basket/addtosave',
    delFromSave: '/basket/delfromsave',
    setBasket: '/basket/set-basket'
  },
  dev: {
    add: '/basket/add',
    itemDelete: '/basket/delete-item',
    clear: '/basket/clear',
    changeQuantity: '/basket/change-quantity',
    setCurrency: '/basket/set-currency',
    setCurrencyIntegration: '/checkout/checkout/set-currency',
    addToSave: '/basket/addtosave',
    delFromSave: '/basket/delfromsave',
    setBasket: '/basket/set-basket'
  }
}
