module.exports = {
  prod: {
    currencies: '/currencies',
    countries: '/countries',
    languages: '/languages',
    addresses: '/addresses',
    get: '/profiles',
    savePrescription: '/account/prescriptions/save',
    fetchPrescriptions: '/account/prescriptions',
    findPrescription: '/account/prescriptions/find',
    deletePrescription: '/account/prescriptions/:id'
  },
  dev: {
    currencies: '/currencies',
    countries: '/countries',
    languages: '/languages',
    addresses: '/addresses',
    get: '/profiles',
    savePrescription: '/account/prescriptions/save',
    fetchPrescriptions: '/account/prescriptions',
    findPrescription: '/account/prescriptions/find',
    deletePrescription: '/account/prescriptions/:id'
  }
}
