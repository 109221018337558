export default {
  name: 'Contacts',
  data () {
    return {
      lang: null
    }
  },
  mounted: function () {
    let parser = document.createElement('a')
    parser.href = window.location.href
    this.lang = parser.pathname.split('/')[1] === 'us' || parser.pathname.split('/')[1] === 'ua' ? parser.pathname.split('/')[1] : null
  }
}
