export const CHANGE_FORM_DATA = 'change_form_data'
export const CHANGE_FORM_DATA_MULTI = 'change_form_data_multi'
export const LOGIN_SUBMIT = 'login_submit'
export const REQUEST_START = 'login_request_start'
export const REQUEST_END = 'login_request_end'
export const FORM_ERROR = 'form_error'
export const FORM_SUCCESS = 'form_success'
export const FORM_MESSAGE = 'form_message'
export const FORM_ERROR_FIELD = 'form_error_field'
export const FORM_FIELD_CLEAR = 'form_field_clear'
export const USER_SIGNED = 'user_signed'
export const SSV4_USER_SIGNED = 'ssv4_user_signed'
export const USER_SIGNED_POPUP = 'user_signed_popup'
export const SSV4_USER_SIGNED_POPUP = 'ssv4_user_signed_popup'
export const USER_SIGNOUT = 'user_signout'
export const PASSWORD_CLEAR = 'password_clear'
export const INPUT_ADDRESS = 'input_address'
export const NEW_USER_DATA = 'new_user_data'
export const SHIPPING_ID = 'shipping_id'

export const CLEAR_BASKET = 'clear_basket'
export const ADD_ADDITIONAL_PRICE = 'add_additional_price'
export const BASKET_LOADED = 'basket_loaded'
export const SSV4_ORDER_LOADED = 'ssv4_basket_loaded'
export const SSV4_SAVED_ITEMS = 'ssv4_saved_items'
export const SSV4_USER = 'ssv4_user'
export const SET_SAVE_FOR_LATER = 'set_save_for_later'
export const DEL_SAVE_FOR_LATER = 'del_save_for_later'
export const SWITCH_WISHLIST = 'switch_wishlist'
export const WISHLIST_LOADED = 'wishlist_loaded'
export const CLEAN_WISHLIST = 'clean_wishlist'
export const ACTIVE_WL = 'active_wishlist'

export const LOAD_CURRENCIES = 'load_currencies'
export const CHANGE_CURRENCY = 'change_currency'

export const LOAD_MENUS = 'load_menus'
export const LOAD_MOBILE_MENU = 'load_mobile_menu'
export const SET_REDIRECTS = 'set_redirects'
export const SET_BREADCRUMBS = 'set_breadcrumbs'
export const ADD_BREADCRUMB = 'add_breadcrumb'
export const SET_PAGE_DESCRIPTION = 'set_page_description'

export const SSV4_USER_DATA = 'ssv4_user_data'

export const REQUEST_BASKET_START = 'basket_request_start'
export const REQUEST_BASKET_FINISHED = 'basket_request_end'

export const REQUEST_CURRENCY_START = 'currency_request_start'
export const REQUEST_CURRENCY_FINISHED = 'currency_request_end'

export const SET_ROUTER_BACK = 'set_router_back'

export const CHANGE_FORM_ERROR = 'change_form_meta'
export const FETCH_ORDERS = 'fetch_orders'
export const DELETE_ORDER = 'delete_order'
export const ORDER_LOADING = 'order_loading'

export const LOAD_CATEGORIES = 'load_categories'
export const LOAD_DESIGNERS = 'load_designers'
export const SET_AUTHORIZATION = 'set_authorization'
export const COUNTRY_LOAD_START = 'country_load_start'
export const COUNTRY_LOAD_END = 'country_load_end'
export const SET_COUNTRIES = 'set_countries'
export const SET_GEO_IP_INFO = 'set_geo_ip_info'
export const GEO_IP_REQUEST = 'geo_ip_request'

export const LOAD_PROMISE_DATA = 'load_promise_data'
export const CLEAR_PROMISE_DATA = 'clear_promise_data'
export const CLEAR_PROMISE_DATA_KEY = 'clear_promise_data_key'

export const CHANGE_LANGUAGE = 'change_language'

export const SET_COOKIES = 'set_cookies'
export const CLEAR_COOKIES = 'clear_cookies'

export const BLOCK_LOAD = 'block_load'
export const RESET_STATE = 'reset_state'
export const SET_HEADERS = 'set_headers'
export const FETCH_URL_BATCH_BY_SID = 'fetch-url-batch'
export const URL_COMMIT = 'url_commit'
export const PREPARE_URL_COMMIT = 'prepare_url_commit'

export const REQUEST_ITEMS_START = 'request_items_start'
export const REQUEST_ITEMS_END = 'request_items_end'
export const FETCH_DATA_REVIEWS = 'fetch_data_reviews'

export const ACCOUNT_FETCH_PRESCRIPTION = 'account_fetch_prescription'
export const ACCOUNT_FETCH_ALL_PRESCRIPTIONS = 'account_fetch_all_prescriptions'

