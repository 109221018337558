import $ from 'jquery'
import {mapActions, mapState} from 'vuex'
import * as types from '@/store/types'
import config from '@/../config'

export default {
  name: 'SignIn',
  data () {
    return {
      email: null,
      password: null,
      host: config.integrationHost
    }
  },
  computed: {
    ...mapState({
      data (state) {
        const {formData: {modalLogin: {familiarEmail = false, ...data} = {}}} = state

        this.email = data.email
        this.password = data.password
        this.familiarEmail = familiarEmail

        return {
          account: 0,
          ...data
        }
      },
      meta (state) {
        const meta = {
          ...state.formMeta['modalLogin'],
          message: state.formMeta.message
        }
        if (meta.formError === 'Access not permitted') {
          meta.message = {
            data: 'Wrong e-mail or password',
            type: 'error'
          }
        }
        if (meta.formError === 'Email wrong') {
          meta.message = {
            data: 'Wrong e-mail',
            type: 'error'
          }
        }
        return meta
      }
    })
  },
  methods: {
    ...mapActions({
      onChange: 'onChangeForm'
    }),
    checkEmail () {
      return this.$store.dispatch('checkModalLogin', this.email)
    },
    submitForm (e) {
      e.preventDefault()

      this.checkEmail()
        .then(() => {
          if (this.data.account || !this.familiarEmail) {
            this.$store.dispatch('onModalLogin')
              .then(err => {
                if (!err) this.$emit('modal-close', true)
              })
              .catch(err => {
                if (err) console.error('Login form error: ', err.error, err.status)
              })
          }
        })
    }
  },
  mounted () {
    $('#loginModal').on('hidden.bs.modal', () => {
      this.email = ''
      this.password = ''
      this.$store.commit(types.FORM_FIELD_CLEAR, {formName: 'modalLogin'})
    })
  }
}
