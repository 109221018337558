import config from '@/../config'
import { mapState } from 'vuex'

export default {
  name: 'ProjectVersion',
  data () {
    return {
      version: {
        front: null,
        back: null
      },
      showVersion: false
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost
    })/* ,
    allowedDisplay: function () {
      let reg = new RegExp('(omnismain.com)')
      let hostname = window.location.host
      return reg.test(hostname)
    } */
  },
  mounted () {
    let self = this
    if (this.showBadge) {
      this.getFrontVersion()
      this.getBackVersion().then(() => {
        self.showVersion = true
      })
    }
  },
  methods: {
    getFrontVersion () {
      const timestamp = Date.now()
      let self = this
      return this.$axios.get('/' + config.buildPrefix + '/static/version.json?' + timestamp, {withCredentials: true})
        .then((data) => {
          if (data.data.version && data.data.deployed) {
            self.version.front = data.data
          }
        })
        .catch(res => {
          return res
        })
    },
    getBackVersion () {
      const timestamp = Date.now()
      let self = this
      return this.$axios.get(this.apiHost + config.prefix + '?getversion&' + timestamp, {withCredentials: true})
        .then((data) => {
          if (data.data.version && data.data.deployed) {
            self.version.back = data.data
          }
        })
        .catch(res => {
          return res
        })
    }
  }
}
