import {mapState, mapActions} from 'vuex'

export default {
  name: 'FlashMessage',
  computed: {
    ...mapState({
      message: function (state) {
        return {
          ...state.formMeta.alertMessage
        }
      }
    }),
    showMessage: function () {
      return this.message && this.message.message
    }
  },
  methods: {
    ...mapActions(['formMessage']),
    hideMessage: function () {
      setTimeout(() => this.formMessage({}), 5000)
    }
  },
  destroyed () {
    this.hideMessage()
  },
  updated () {
    if (this.showMessage) {
      this.hideMessage()
    }
  }
}
