var path = require('path')

module.exports = {
  env: require('./test.env'),
  port: 1111,
  apiHost: 'https://prod-yii.omnismain.com',
  integrationHost: 'https://ss-brahmin.omnismain.com',
  cdnUrl: 'https://d9qzjwuieyamt.cloudfront.net',
  cdnUrlPrefix: '/public/en/',
  prefix: '/rest',
  integrationPrefix: '/api',
  autoOpenBrowser: true,
  assetsSubDirectory: 'static',
  assetsPublicPath: '/',
  defLocale: 'en',
  subDomain: '',
  proxyTable: {},
  basket: require('./Components/basket').dev,
  cartSSv4: require('./Components/cartSSv4').prod,
  products: require('./Components/products').dev,
  currencies: require('./Components/currencies').dev,
  countries: require('./Components/countries').prod,
  checkout: require('./Components/checkout').dev,
  prescriptions: require('./Components/prescriptions').dev,
  wishlist: require('./Components/wishlist').dev,
  orders: require('./Components/orders').dev,
  profiles: require('./Components/profiles').dev,
  users: require('./Components/users').dev,
  menu: require('./Components/menu').dev,
  routes: require('./Components/routes').dev,
  blocks: require('./Components/blocks').dev,
  elastic: require('./Components/elastic').prod,
  designers: require('./Components/designers').dev,
  reviews: require('./Components/reviews').dev,
  categories: require('./Components/categories').dev,
  gettexts: require('./Components/gettexts').dev,
  // CSS Sourcemaps off by default because relative paths are "buggy"
  // with this option, according to the CSS-Loader README
  // (https://github.com/webpack/css-loader#sourcemaps)
  // In our experience, they generally work as expected,
  // just be aware of this issue when enabling this option.
  cssSourceMap: false,
  vat: 1.2,
  cluster: {
    max_open_processes: 8,
    per_core: 1,
    limit: 2,
    compile: 'linux-x64-10.13.0',
    checkInterval: 1000,
    step: 2,
    freeTimeout: 5, // minutes
    timeoutOnMaxWorkerReached: 300,
    memoryLimit: 256,
    maxPreFetchDepth: 10
  },
  ssv4: [
    // 'cart/index'
  ]
}
