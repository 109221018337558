module.exports = {
  prod: {
    search: '/search/routes',
    getPagesRoutes: '/search/routes/get-pages-routes',
    findByCategory: '/search/routes/by-category',
    getRedirects: '/search/routes/get-redirects',
    getUrlByName: '/search/routes/get-url',
    getUrlBatchBySID: '/search/routes/get-url-batch'
  },
  dev: {
    search: '/search/routes',
    getPagesRoutes: '/search/routes/get-pages-routes',
    findByCategory: '/search/routes/by-category',
    getRedirects: '/search/routes/get-redirects',
    getUrlByName: '/search/routes/get-url',
    getUrlBatchBySID: '/search/routes/get-url-batch'
  }
}
