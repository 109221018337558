module.exports = {
  prod: {
    KEY: 'AKIAJADMGFH5EJ4DO32Q',
    SECRET_KEY: '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',
    CF_DISTRIBUTION_ID: 'E380GGDMSRQIPK',
    BUCKET: 'ssfront',
    HOST: 'https://s3-website-eu-west-1.amazonaws.com/',
    region: 'eu-west-1',
    cdn: 'https://d9qzjwuieyamt.cloudfront.net'
  },
  dev: {
    KEY: 'AKIAJADMGFH5EJ4DO32Q',
    SECRET_KEY: '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',
    CF_DISTRIBUTION_ID: 'E380GGDMSRQIPK',
    BUCKET: 'ssfront',
    HOST: 'https://s3-website-eu-west-1.amazonaws.com/',
    region: 'eu-west-1',
    cdn: 'https://d9qzjwuieyamt.cloudfront.net'
  }
}
