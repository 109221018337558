import SettingsForms from '@/components/scripts/PageFooter/FooterNavigation/SettingsForms/index.vue'
import Socials from '@/components/scripts/PageFooter/FooterNavigation/Socials/index.vue'
import PaymentsTypes from '@/components/scripts/PageFooter/FooterNavigation/PaymentsTypes/index.vue'
import { currentCountry } from '@/../config/helper'

export default {
  name: 'FooterNavigation',
  components: {
    SettingsForms,
    Socials,
    PaymentsTypes
  },
  data () {
    return {
      sspayMode: false,
      domain: false
    }
  },
  created: function () {
    this.domain = currentCountry()
    this.sspayMode = !(this.$route.query.sspay === undefined)
  }
}
