<template lang="pug">
  section#discreteMessageBar(v-if="showBlock").discrete-message-bar
    .container-fluid
      .row
        .col-12.px-lg-3.py-2.small
          component(:is="$transComponent('DiscreteMessageBar', geoLang)")
</template>

<style lang="scss">
  .discrete-message-bar {
    background-color: #4c4c54;
    color: #fff;
    line-height: 1;
    font-size: .875rem;
    text-align: center;

    img.small-img {
      width: 17px;
    }

    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
</style>

<script>
  import { mapState } from 'vuex'
  import $ from 'jquery'

  export default {
    name: 'DiscreteMessageBar',
    computed: {
      ...mapState({
        currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
        currencies: (state) => state.currency.allCurrency,
        geoInfo: (state) => state.geoInfo,
        countries: (state) => state.countries
      }),
      countryCodeLoaded: function () {
        return this.geoInfo && this.geoInfo.countryCode
      },
      currentCountryCode: function () {
        let countryCode = ''
        if (this.countryCodeLoaded) {
          countryCode = this.geoInfo.countryCode
        }
        return countryCode
      },
      geoCountry: function () {
        let countryCode = this.currentCountryCode.toUpperCase()
        return Object.values(this.countries).find(obj => obj && (obj.country_code_2 === countryCode || obj.country_code_3 === countryCode))
      },
      geoLang: function () {
        let lang = this.lang
        if (this.countryCodeLoaded) {
          let countryCode = this.currentCountryCode.toUpperCase()
          if (this.allowedCustomers[countryCode]) {
            lang = this.allowedCustomers[countryCode].lang
          }
        }
        return lang
      },
      isEnglishDomain: function () {
        let englishDomains = Object.keys(this.allowedCustomers)
          .filter(loc => {
            return this.allowedCustomers[loc].lang === 'en' || this.allowedCustomers[loc].lang === 'us'
          })
        return englishDomains.indexOf(this.currentCountryCode) > -1
      },
      isWrongLocation: function () {
        let isWrong = false
        if (this.countryCodeLoaded) {
          let countryCode = this.currentCountryCode
          let currentLocale = '/' + countryCode.toLowerCase()
          let currentPath = this.currentRoute.path
          if ((currentPath === '/') && this.isEnglishDomain) {
            isWrong = false
          } else {
            isWrong = countryCode in this.allowedCustomers && (currentLocale !== currentPath)
          }
        }
        return isWrong
      },
      cookieIsSet: function () {
        return this.$cookies.isKey('hideLocaleMessage')
      },
      showBlock: function () {
        return this.isWrongLocation && (this.cookieIsSet === false)
      }
    },
    data () {
      return {
        allowedCustomers: {
          GB: {
            lang: 'en'
          },
          UK: {
            lang: 'en'
          },
          US: {
            lang: 'us'
          },
          DE: {
            lang: 'de'
          },
          CA: {
            lang: 'ca'
          },
          AU: {
            lang: 'au'
          }
        },
        currentRoute: this.$route
      }
    },
    methods: {
      hideBlock (route) {
        this.$cookies.set('hideLocaleMessage', 1, 60 * 60 * 12)
        if (route) this.$router.push({path: route})
        this.$forceUpdate()
      },
      changeLanguage (lang) {
        this.$i18n.locale = lang
        localStorage.setItem('lang', lang)
        this.$store.dispatch('changeLanguage', lang)
      }
    },
    mounted () {
      let self = this
      $(document).on('click', '#toLocationSite', function () {
        let redirectionRoute = self.isEnglishDomain ? '/' : '/' + self.geoLang
        self.changeLanguage(self.geoLang)
        self.hideBlock(redirectionRoute)
      })
    },
    destroyed () {
      this.hideBlock()
    },
    watch: {
      '$route': function () {
        this.currentRoute = this.$route
        this.hideBlock()
      },
      'geoCountry': function () {
        this.$store.dispatch('setCurrency', this.geoCountry.currency_id)
      }
    }
  }
</script>
