const debug = require('debug')('error')

const currentCountry = function () {
  let parser = document.createElement('a')
  parser.href = window.location.href
  return (parser.pathname.split('/')[1] === 'us' || parser.pathname.split('/')[1] === 'ua') ? parser.pathname.split('/')[1] : null
}

const reverseRouteName = function (str) {
  let replaceCharacters = {
    'Ä': 'Ae',
    'Ö': 'Oe',
    'ä': 'ae',
    'è': 'e',
    'é': 'e',
    'ö': 'oe',
    'ü': 'ue',
    'Ü': 'Ue',
    'ß': 'ss'
  }
  if (str && str.length > 0) {
    for (let key in replaceCharacters) {
      if (replaceCharacters.hasOwnProperty(key)) {
        let re = new RegExp(key, 'g')
        str = str.replace(re, replaceCharacters[key])
      }
    }

    let name = str.replace(/\(|\)/g, '')
    name = name.replace(/([^a-zA-Z0-9]+)|(\s+)/gi, '-')
    name = name.replace(/(&|&amp;)/gi, '-and-')
    name = name.replace('/(-+)/g', '-').toLowerCase()
    return name
  }
  return str
}

const parseQueryString = function (queryString) {
  let params = {}
  let queries, temp, i, l
  queryString = queryString.replace(/^\?/, '')
  queries = queryString.split('&')
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=')
    if (temp.length > 1) {
      let key = decodeURIComponent(temp[0])
      params[key] = []
      if (temp[1].indexOf(',') !== -1) {
        temp[1].split(',').forEach(val => {
          params[key].push(val.trim())
        })
      } else {
        params[key] = temp[1]
      }
    }
  }
  return params
}

const arrayIntersect = function (array1, array2) {
  return array1.filter((obj) => array2.indexOf(obj.id) !== -1)
}

const arrayToObject = (array) =>
  array.reduce((obj, item, index) => {
    obj[index] = item
    return obj
  }, {})

const getSearchString = function (filter, withSign = true) {
  let params = []
  for (let key in filter) {
    let values = Array.isArray(filter[key]) ? filter[key].join(',') : filter[key]
    if (values.length !== 0) {
      params.push(decodeURIComponent(key) + '=' + values)
    }
  }
  return (params.length > 0 ? (withSign ? '?' : '') + params.join('&') : '')
}

const addSlashes = function (str) {
  return str.replace(/[\\"'\[\]]/g, '\\$&')
}

const parseCookieJar = function (jar, validDomain = false) {
  var cookieOut = []
  Object.keys(jar.store.idx).forEach(function (domain) {
    if (validDomain && validDomain !== domain) {
      return
    }

    Object.keys(jar.store.idx[domain]).forEach(function (path) {
      Object.keys(jar.store.idx[domain][path]).forEach(function (cookie) {
        cookieOut.push(JSON.parse(JSON.stringify(jar.store.idx[domain][path][cookie])))
      })
    })
  })
  return cookieOut
}

const parseCookieJarToString = function (jar, validDomain = false) {
  var cookieOut = parseCookieJar(jar, validDomain)
  let cookieString = cookieOut ? cookieOut.map(obj => (obj.key + '=' + obj.value)).join(';') : null
  return cookieString
}

const parseCookieToString = function(cookies) {
  let cookieString = null
  if (cookies) {
    cookieString = Object.keys(cookies).map(key => {
      try {
        return key + '=' + encodeURIComponent(decodeURIComponent(cookies[key]))
      } catch (e) {
        debug('Decode cookie failed: ' + JSON.stringify({
          key,
          cookie: cookies[key],
          e
        }))
        try {
          return key + '=' + escape(unescape(cookies[key]))
        } catch (e) {
          debug('Unescape cookie failed: ' + JSON.stringify({
            key,
            cookie: cookies[key],
            e
          }))
          return undefined
        }
      }
    }).join(';')
  }
  return cookieString
}

const propagateToGlobal  = function (window) {
  for (let key in window) {
    if (!window.hasOwnProperty(key)) continue
    if (key in global) continue

    global[key] = window[key]
  }
}

const escaping = function (str) {
  return str.replace(/[\\"']/g, '\\$&').replace(/\t/g, '\\t').replace(/\n/g, '\\n')
}

const isNativeFunction = (f) => {
  return !!f && (typeof f).toLowerCase() == 'function'
    && (f === Function.prototype
    || /^\s*function\s*(\b[a-z$_][a-z0-9$_]*\b)*\s*\((|([a-z$_][a-z0-9$_]*)(\s*,[a-z$_][a-z0-9$_]*)*)\)\s*{\s*\[native code\]\s*}\s*$/i.test(String(f)))
}

const getDescendantProp = (obj, path) => (
  path.split('.').reduce((acc, part) => acc && acc[part], obj)
)

const setNestedKey = (obj, path, value) => {
  if (path.length === 1) {
    obj[path] = value
    return
  }
  return setNestedKey(obj[path[0]], path.slice(1, path.length), value)
}

const iterate = function (keys, obj, currObj, stack) {
  for (var property in currObj) {
    if (currObj.hasOwnProperty(property)) {
      if (typeof currObj[property] === 'object') {
        iterate(keys, obj, currObj[property], stack + (stack.length > 0 ? '.' : '') + property)
      } else {
        let key = stack + (stack.length > 0 ? '.' : '') + property
        let value = getDescendantProp(obj, key)
        if (value && typeof value === 'string' && value.indexOf('__fn__') === 0) {
          keys.push(key)
        }
      }
    }
  }
}

const queryObject = function (query, key, value) {
  key = decodeURIComponent(key)
  if (query[key]) {
    if (typeof query[key] === 'string') {
      query[key] = query[key].split(',')
    }
    if (typeof query[key] === 'object') {
      let index = query[key].findIndex((v) => v.toString() === value.toString())
      if (index !== -1) {
        query[key].splice(index, 1)
      } else {
        query[key].push(value)
      }
    }
  } else {
    query[key] = [value]
  }
  if (query[key] && query[key].length > 0) {
    query[key] = query[key].join(',')
  } else {
    delete query[key]
  }
  return query
}

module.exports = {
  reverseRouteName,
  parseQueryString,
  arrayIntersect,
  arrayToObject,
  getSearchString,
  addSlashes,
  parseCookieJar,
  parseCookieJarToString,
  parseCookieToString,
  propagateToGlobal,
  escaping,
  isNativeFunction,
  getDescendantProp,
  setNestedKey,
  iterate,
  queryObject,
  currentCountry
}

