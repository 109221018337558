module.exports = {
  prod: {
    add: '/basket/add',
    itemDelete: '/delete-item/',
    clear: '/basket/clear',
    changeQuantity: '/set-item-qty/',
    setCurrency: '/set-currency/',
    setCurrencyIntegration: '/checkout/checkout/set-currency',
    getSavedItems: '/get-saved-items/',
    addToSave: '/save-item/',
    moveFromSave: '/move-saved-item/',
    removeFromSave: '/saved-remove/',
    fetch: '/fetch-basket/',
    fetchByID: '/fetch-order/?order_id=<:id>&cart=true',
    getCurrencies: '/get-currencies/',
    applyPromoCode: '/apply-promo-code/',
    checkUser: '/login/?checkStatus=true'
  },
  dev: {
    add: '/basket/add',
    itemDelete: '/delete-item/',
    clear: '/basket/clear',
    changeQuantity: '/set-item-qty/',
    setCurrency: '/set-currency/',
    setCurrencyIntegration: '/checkout/checkout/set-currency',
    getSavedItems: '/get-saved-items/',
    addToSave: '/save-item/',
    moveFromSave: '/move-saved-item/',
    removeFromSave: '/saved-remove/',
    fetch: '/fetch-basket/',
    fetchByID: '/fetch-order/?order_id=<:id>&cart=true',
    getCurrencies: '/get-currencies/',
    applyPromoCode: '/apply-promo-code/',
    checkUser: '/login/?checkStatus=true'
  }
}
