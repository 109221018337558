import ExcellentCarousel from '@/components/scripts/PageHeader/ExcellentCarousel/index.vue'
import UpperBar from '@/components/scripts/PageHeader/UpperBar/index.vue'
import LogoLine from '@/components/scripts/PageHeader/LogoLine/index.vue'
import Flash from '@/components/scripts/FlashMessage/index.vue'
import DiscreteMessageBar from '@/components/scripts/PageHeader/DiscreteMessageBar/index.vue'

export default {
  name: 'PageHeader',
  components: {
    ExcellentCarousel,
    UpperBar,
    DiscreteMessageBar,
    LogoLine,
    Flash
  },
  computed: {
    showCarousel () {
      return ['Register', this.$rname('Login'), 'Reviews', 'SalePage'].indexOf(this.$route.name) < 0
    },
    showFlash () {
      return ['UpdateProfile', 'AccountAddresses'].indexOf(this.$route.name) < 0
    }
  }
}
